<template>
  <div class="tab-content" id="PTtab-5" :key="'PTtab-5'" >
      <div class="pagenamPT">
        <h3 class="pagename2">입금내역</h3>
      </div>
      <div class="PTsch">
        <div class="datesearchPT">
          <date-filter :retail="true" @search="loadData(1)"
                       @update="onChangeDateTable"
                       :defaultDay="0"
                       :startDate="mainTableDate.startDate"
                       :endDate="mainTableDate.endDate"
                       :isOldYn="true"
                       :id="'main-date-checkbox1'"
                       @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
        </div>
        <div class="datesearchM">
           <date-filter-mobile :retail="true" @search="loadData(1)"
                              @update="onChangeDateTable"
                              :defaultDay="0"
                              :startDate="mainTableDate.startDate"
                              :endDate="mainTableDate.endDate"
                              :isOldYn="true"
                              :id="'main-date-checkbox1'"
                              @setOldYn="setOldYn" :oldYn="reqData.oldYn"
          />
        </div>
        <div class="searchPT">
          <select  v-model="reqData.cashType">
            <option value="" selected>전체</option>
            <option value="1">입금</option>
            <option value="-1">출금</option>
            <option value="2">관리자 지급</option>
            <option value="-2">관리자 회수</option>
            <option value="33">상위 파트너 지급</option>
            <option value="-33">상위 파트너 회수</option>
            <option value="4">포인트전환</option>
          </select>
          <select  v-model="reqData.searchType">
            <option value="memId" selected>아이디</option>
            <option value="memNick">닉네임</option>
            <option value="recommenderId">상위유저</option>
          </select>
          <input type="text" :placeholder="$t('front.search.emptySearch')"  v-model="reqData.searchWord"/>
          <a @click="loadData(1)">
            <img src="@/assets/img/search.png" alt=""/>
          </a>
        </div>
      </div>
      <div class="strTablescr">
         <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
                <col width="10%">
                <col width="10%">
                <col width="17%">
                <col width="19%">
                <col width="19%">
                <col width="10%">
                <col width="15%">
              </colgroup>
              <thead>
                <tr>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>상위 파트너</th>
                  <th>처리 전 보유금</th>
                  <th>처리금액</th>
                  <th>종류</th>
                  <th>처리시각</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="cashList.length > 0">
                  <template v-for="item in cashList" :key="item.updDate">
                    <tr>
                      <td>{{item.memId}}</td>
                      <td>{{item.memNick}}</td>
                      <td>{{item.recommenderId}} / {{item.recommenderNick}}</td>
                      <td>{{thousand(item.preCashAmt)}}</td>
                      <td>{{thousand(item.cashAmt.replace('-', ''))}}</td>
                      <td>{{item.cashDesc}}</td>
                      <td>{{dateFormatAll(item.updDate)}}</td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                  </tr>
                </template>
              </tbody>
            </table>
         </div>
         <div class="strTableM">
            <div class="strTablePTM">
               <template v-if="cashList.length > 0">
                 <template v-for="item in cashList" :key="item.updDate">
                   <ul>
                     <li><em>아이디</em>{{item.memId}}</li>
                     <li><em>닉네임</em>{{item.memNick}}</li>
                     <li><em>상위 파트너</em>{{item.recommenderId}} / {{item.recommenderNick}}</li>
                     <li><em>처리 전 보유금</em>{{thousand(item.preCashAmt)}}</li>
                     <li><em>처리금액</em>{{thousand(item.cashAmt.replace('-', ''))}}</li>
                     <li><em>종류</em>{{item.cashDesc}}</li>
                     <li><em>처리시각</em>{{dateFormatAll(item.updDate)}}</li>
                   </ul>
                 </template>
               </template>
               <template v-else>
                 <ul>
                    <li>내역 없음</li>
                 </ul>
               </template>
            </div>
         </div>
      </div>

      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="loadData"
      />
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Pagination from '@/components/ui/Pagination.vue'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import { getBottomCashList, partnerLevels } from '@/api/retail'
export default {
  name: 'PartnerCash',
  components: { DateFilter, DateFilterMobile, Pagination },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      mainTableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      reqData: {
        cashType: '',
        searchType: '',
        searchWord: '',
        chkTodayYn: 'N',
        startDate: '',
        endDate: '',
        offset: -1,
        oldYn: 'N'
      },
      cashList: []
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.reqData.startDate = getDateStr(new Date(this.mainTableDate.startDate))
    this.reqData.endDate = getDateStr(new Date(this.mainTableDate.endDate))
    await this.loadData()
    this.emitter.emit('Loading', false)
  },
  methods: {
    thousand,
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangeDateTable (value) {
      console.log(value)
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    async loadData (page) {
      if (!page) {
        page = this.pageInfo.page
      }
      this.emitter.emit('Loading', true)
      const params = { ...this.reqData, page: page }
      await getBottomCashList(params).then(res => {
        this.emitter.emit('Loading', false)
        const data = res.data.data
        if (data) {
          this.cashList = data.list

          if (data.pageInfo) {
            this.pageInfo = data.pageInfo
          }
        }
      })
    }
  }
}
</script>
<style scoped>

</style>

<style scoped src="@/styles/striNew.css"></style>
